<template>
  <div>
    <b-card>
      <own-table
        ref="karirTable"
        name-table="karir"
        :table-columns="[  
          { label: 'First Name', key: 'first_name', sortable: true },
          { label: 'Last Name', key: 'last_name', sortable: true },
          { label: 'No Telepon', key: 'phone_number', sortable: true },
          { label: 'Tanggal Lahir', key: 'birth', sortable: true },
          { label: 'Email', key: 'email', sortable: true },
          { label: 'Gender', key: 'gender', sortable: true },
          { label: 'Main Position', key: 'main_position', sortable: true },
          { label: 'Posisi Lainnya', key: 'optional_position', sortable: true },  
          { label: 'Expected Salary', key: 'expected_salary', sortable: true },        
          { label: 'Actions', key: 'actions', sortable: false }
        ]"
        :data="apiData"
        :total-items="pagination.total"
        :current-page="pagination.current_page"
        :per-page="pagination.per_page"
        :disable-create-btn="true"
        :use-custom-filter="true"
        :custom-query-filter="queryFilter"
        sort-by="created_at"
        sort-direction="desc"
        @searchFieldOnChange="searchOnChange"
        @page-change="fetchCareers"
        @sort-change="onSortChange"
      >

      <template #cell(expected_salary)="{ data }">
        <div>{{ formatCurrency(data.item.expected_salary) }}</div>
      </template>
        <!-- Slot untuk kolom actions -->
        <template #cell(actions)="{ data }">
          <div class="d-flex justify-content-center">
            <!-- Tombol Download PDF -->
            <b-button
              variant="info"
              size="sm"
              class="mr-1"
              @click="downloadPDF(data)"
              v-b-tooltip.hover
              title="Download PDF Resume"
            > Print PDF
            </b-button>

            <!-- Tombol Delete -->
            <!-- <b-button
              variant="danger"
              size="sm"
              @click="deleteCareer(data.id)"
              v-b-tooltip.hover
              title="Delete Career"
            >
              <b-icon-trash />
            </b-button> -->
          </div>
        </template>
      </own-table>
    </b-card>

    <!-- Delete Career Modal -->
    <b-modal
      v-model="deleteModal"
      centered
      title="Hapus Karir"
      scrollable
      ok-title="Ya"
      cancel-title="Tidak"
      no-close-on-backdrop
      @ok="confirmDelete"
    >
      <h5 class="mb-2">Apakah Anda yakin ingin menghapus data karir ini?</h5>
      <template #modal-footer="{ cancel, ok }">
        <b-button :disabled="confirmLoading" @click="cancel">Tidak</b-button>
        <b-button :disabled="confirmLoading" variant="danger" @click="ok">
          <b-spinner v-if="confirmLoading" small class="mr-1" />
          <span>Ya</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import { 
  BCard, 
  BModal, 
  BButton, 
  BSpinner, 
  VBTooltip, 
  BIconFilePdf, 
  BIconTrash 
} from 'bootstrap-vue';
import OwnTable from '@/components/Table/Table.vue';
import service from '@/components/Table/service';
import { api } from 'v-viewer';

export default {
  components: {
    BCard,
    BModal,
    BButton,
    BSpinner,
    OwnTable,
    BIconFilePdf,
    BIconTrash
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const apiData = ref([]);
    const queryFilter = ref('');
    const deleteModal = ref(false);
    const confirmLoading = ref(false);
    const selectedCareer = ref(null);

    const pagination = ref({
      current_page: 1,
      per_page: 10,
      total: 0,
    });

    // Fungsi format mata uang (Rupiah)
    const formatCurrency = (value) => {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0, // Tidak menampilkan angka desimal
      }).format(value);
    };

    const fetchCareers = async (page = pagination.value.current_page) => {
      try {
        const response = await service.index({
          url: 'karir',
          params: {
            page,
            limit: pagination.value.per_page,
            sort_by: 'created_at',
            direction: 'desc',
            q: queryFilter.value,
          },
        });

        const careers = response.data?.data || [];
        pagination.value = {
          current_page: response?.data?.pagination?.current_page || 1,
          per_page: response?.data?.pagination?.per_page || 10,
          total: response?.data?.pagination?.total || 0,
        };

        apiData.value = careers.map((item, index) => {
          const age = calculateAge(item.birth); // Hitung usia di sini

          return {
            id: item.id,
            no: index + 1,
            first_name: item.first_name || '-',
            last_name: item.last_name || '-',
            email: item.email || '-',
            gender: item.gender || '-',
            main_position: item.main_position || '-',
            expected_salary: formatCurrency(item.expected_salary || 0), // Format salary ke Rupiah
            resume_filename: item.resume_filename || '',
            optional_position: item.optional_position || '-',
            birth: item.birth || '-',
            age: age, // Sertakan usia dalam data
            phone_number: item.phone_number || '-',
          };
        });
      } catch (error) {
        console.error('Error fetching career data:', error);
        apiData.value = [];
      }
    };

    const searchOnChange = (val) => {
      queryFilter.value = val;
      fetchCareers();
    };

    const onSortChange = ({ sortBy, sortDirection }) => {
      pagination.value.sort_by = sortBy;
      pagination.value.sort_direction = sortDirection;
      fetchCareers();
    };

    const deleteCareer = (careerId) => {
      selectedCareer.value = careerId;
      deleteModal.value = true;
    };

    const confirmDelete = async () => {
      confirmLoading.value = true;
      try {
        await service.delete({ url: `api/auth/karir/${selectedCareer.value}` });
        deleteModal.value = false;
        fetchCareers();
      } catch (error) {
        console.error('Error deleting career:', error);
      } finally {
        confirmLoading.value = false;
      }
    };

    const downloadPDF = (data) => {
      const resumeFilename = data.resume_filename;
      if (resumeFilename) {
        const pdfUrl = `https://ingco.co.id/public/assets/files/${resumeFilename}`;
        window.open(pdfUrl, '_blank');
      } else {
        alert('Resume filename is missing or empty.');
      }
    };

    const calculateAge = (birthDate) => {
      if (!birthDate) return '-';      
      const [year, month, day] = birthDate.split('-').map(Number);
      const today = new Date();
      const birth = new Date(year, month - 1, day); 
      
      let age = today.getFullYear() - birth.getFullYear();
      const monthDiff = today.getMonth() - birth.getMonth();
      const dayDiff = today.getDate() - birth.getDate();
              
      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
      }

      return age;
    };


    onMounted(() => fetchCareers());

    return {
      apiData,
      pagination,
      queryFilter,
      deleteModal,
      confirmLoading,
      searchOnChange,
      onSortChange,
      downloadPDF,
      deleteCareer,
      confirmDelete,
      formatCurrency,
      calculateAge,
    };
  },
};
</script>

<style scoped>
.btn-sm i {
  font-size: 0.8rem;
}
</style>