var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('own-table',{ref:"karirTable",attrs:{"name-table":"karir","table-columns":[  
        { label: 'First Name', key: 'first_name', sortable: true },
        { label: 'Last Name', key: 'last_name', sortable: true },
        { label: 'No Telepon', key: 'phone_number', sortable: true },
        { label: 'Tanggal Lahir', key: 'birth', sortable: true },
        { label: 'Email', key: 'email', sortable: true },
        { label: 'Gender', key: 'gender', sortable: true },
        { label: 'Main Position', key: 'main_position', sortable: true },
        { label: 'Posisi Lainnya', key: 'optional_position', sortable: true },  
        { label: 'Expected Salary', key: 'expected_salary', sortable: true },        
        { label: 'Actions', key: 'actions', sortable: false }
      ],"data":_vm.apiData,"total-items":_vm.pagination.total,"current-page":_vm.pagination.current_page,"per-page":_vm.pagination.per_page,"disable-create-btn":true,"use-custom-filter":true,"custom-query-filter":_vm.queryFilter,"sort-by":"created_at","sort-direction":"desc"},on:{"searchFieldOnChange":_vm.searchOnChange,"page-change":_vm.fetchCareers,"sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"cell(expected_salary)",fn:function(ref){
      var data = ref.data;
return [_c('div',[_vm._v(_vm._s(_vm.formatCurrency(data.item.expected_salary)))])]}},{key:"cell(actions)",fn:function(ref){
      var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1",attrs:{"variant":"info","size":"sm","title":"Download PDF Resume"},on:{"click":function($event){return _vm.downloadPDF(data)}}},[_vm._v(" Print PDF ")])],1)]}}])})],1),_c('b-modal',{attrs:{"centered":"","title":"Hapus Karir","scrollable":"","ok-title":"Ya","cancel-title":"Tidak","no-close-on-backdrop":""},on:{"ok":_vm.confirmDelete},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
      var cancel = ref.cancel;
      var ok = ref.ok;
return [_c('b-button',{attrs:{"disabled":_vm.confirmLoading},on:{"click":cancel}},[_vm._v("Tidak")]),_c('b-button',{attrs:{"disabled":_vm.confirmLoading,"variant":"danger"},on:{"click":ok}},[(_vm.confirmLoading)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_c('span',[_vm._v("Ya")])],1)]}}]),model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('h5',{staticClass:"mb-2"},[_vm._v("Apakah Anda yakin ingin menghapus data karir ini?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }